<template>
  <main id="content" role="main">
    <section class="report_group">
      <section class="report_box">
        <div class="contest-detail">
          <div class="title">{{ detail.title }}</div>
          <ul class="info">
            <!-- TODO: 상시모집일 경우 모집기간 없음 -->
            <li>
              <span class="subtitle">{{ $t('content.accelerating.Common.txt20') }}</span>
              <span v-if="detail.isAlwaysRecruitmen">{{ $t('content.accelerating.Common.txt29') }}</span>
              <span v-else>{{ detail.startDate }} - {{ detail.endDate }}</span>
            </li>
            <li>
              <span class="subtitle">{{ $t('content.accelerating.Common.txt21') }}</span>
              <strong>{{ detail.announcementOfPass }}</strong></li>
          </ul>
          <div class="floating" v-if="detail.state === 1" ref="accApplyFloatingButton">
            <a :href="`/programs/application/${detail.id}/apply/form`" class="link">
              <strong>{{ $t('content.accelerating.Common.txt30') }}</strong>Click
            </a>
          </div>
          <div class="intro">
            <img v-for="img in detail.imageBannerList" :src="`${ img.url || example }`" />
          </div>
        </div>
      </section>
    </section>
  </main>
</template>

<script>

import { mixin as mixinHelperUtils } from '@/common/helperUtils';
import { APIs } from '@/common/portalApi';
import { gsap } from '@/common/motions';
import PopupApply from '@/components/popup/PopupApplyDetail.vue';

let lastKnownWindowScrollY = 0;
let ticking = false;

export default {
  name: 'ContestDetail',
  components: {
  },
  data() {
    return {
      example : 'https://staginggangnam.dreamplus.asia/assets/images/company/all_noimg.png',
      detail: {
        title: null,
        startDate: null,
        endDate: null,
        announcementOfPass: null,
      }
    };
  },
  mixins: [
    mixinHelperUtils,
  ],
  created() {
    if (this.$route.params.id) {
      this.getDetail();
    }
    window.addEventListener('scroll', this.onWindowScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.onWindowScroll);
  },
  methods: {
    getDetail() {
      this.portalApiClient({
        url: `${APIs.CONTEST_API}/${this.$route.params.id}`,
        method: 'get',
        data: {}
      }).then(({ data }) => {
        if (data.result) {
          this.detail = data.data;
          if (this.detail && this.detail.imagePopupList && this.detail.imagePopupList.length > 0) {
            this.showPopup(PopupApply, 'pop-list', {popupList: this.detail.imagePopupList});
          }
        } else {
          this.showAlert(data.message);
        }
      });
    },
    /**
     * window 스크롤 이벤트 처리
     * @see window.scrollY : https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollY
     *      * ie11 : https://github.com/zwug/react-full-page/pull/31/commits/3383968609c9437cf1d11d9ef24aaa51eb2fad2b
     * @see ease : https://greensock.com/docs/v3/Eases
     */
     onWindowScroll() {
      lastKnownWindowScrollY = window.pageYOffset || 0; // ie11
      if (!ticking) {
        window.requestAnimationFrame(() => {
            //  공모 지원하기 버튼 커버로는 이동하지 않도록
            const el = this.$refs.accApplyFloatingButton;
            gsap.to(
              el,
              {
                y: lastKnownWindowScrollY,
                duration: 0.5,
                ease: 'power1.inOut',
              },
            );
          ticking = false;
        });

        ticking = true;
      }
    },
  }
};
</script>

<style>
.contest-detail .floating {
    position: absolute !important;
    right: 110px;
    top: -110px;
    text-align: right;
    padding-right: 90px;
    z-index: 100;
}
@media screen and (min-width: 769px) {
  .contest-detail .floating .link {
    width: 134px;
  }
}
</style>
